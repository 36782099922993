import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpClient
} from '@angular/common/http';
import { Observable, Subject, catchError, switchMap, tap, throwError } from 'rxjs';
import { ApiUrls } from 'src/shared/constants/http-urls';
import { Router } from '@angular/router';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();

    constructor(private request: HttpClient, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
      // Handle request
      request = this.addAuthHeader(request);

      // Handle response
      return next.handle(request).pipe(catchError(error => {
          return this.handleResponseError(error, request, next);
      }));
    }

    handleResponseError(error: any, request?: HttpRequest<any>, next?: HttpHandler): Observable<any> {
      // if refreshToken api is the broken one, then do not try to refresh token
      if (request?.url === ApiUrls.refreshToken) {
        return throwError(() => error);
      }

      // Invalid token error
      if (error.status === 401 && request && next) {
        return this.refreshToken().pipe(
          switchMap(() => {
            request = this.addAuthHeader(request as HttpRequest<any>);
            return next.handle(request as HttpRequest<any>).pipe(catchError(error => {
              return this.handleResponseError(error, request, next);
            }));
          }),
          catchError(e => {
            if (e.status !== 401) {
              return this.handleResponseError(e);
            } else {
              this.logout();
            }
            return e;
          }));
      } else {
        return throwError(() => error);
      }
    }

    refreshToken(): Observable<any> {
      if (this.refreshTokenInProgress) {
        return new Observable(observer => {
          let sub = this.tokenRefreshedSource.subscribe(() => {
            observer.next();
            observer.complete();
            sub.unsubscribe();
          });
        });
      } else {
        this.refreshTokenInProgress = true;
        const tokens = {
          accessToken: sessionStorage.getItem('accessToken'),
          refreshToken: sessionStorage.getItem('refreshToken'),
        }
        return this.request.post(ApiUrls.refreshToken, tokens).pipe(
          tap((res: any) => {
            sessionStorage.setItem("LoggedIn", "true");
            sessionStorage.setItem("accessToken", res.token);
            sessionStorage.setItem("refreshToken", res.refreshToken);
            sessionStorage.setItem("userName", res.givenName);
            this.refreshTokenInProgress = false;
            this.tokenRefreshedSource.next(true);
          }),
          catchError((err) => {
            this.refreshTokenInProgress = false;
            this.logout();
            return err;
          }));
      }
    }

    addAuthHeader(request: HttpRequest<any>) {
      const accessToken = sessionStorage.getItem('accessToken');
      const token_type = 'Bearer';
      if (accessToken && request) {
          return request.clone({headers: request.headers.set('Authorization', `${token_type} ${accessToken}`)});
      }
      return request;
    }

    logout() {
      sessionStorage.removeItem("LoggedIn");
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("refreshToken");
      this.router.navigate(['/login']);
    }
}
